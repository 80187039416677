import React, { Component } from 'react';
import BloggItem from './BloggItem';
import Header from './Header';
import SectionAbout from './SectionAbout';
import SectionTitle from './SectionTitle';
import SectionMembers from './SectionMembers';

class BloggItemList extends Component {
	render() {
		const bloggItems = this.props.posts.reverse();
		return (
			<span>
				<Header></Header>
				<SectionTitle title="Senaste avsnittet"></SectionTitle>
				<div className="container">
					<BloggItem data={bloggItems[0]}></BloggItem>
				</div>
				<SectionAbout></SectionAbout>
				<SectionTitle title="Programets medlemmar"></SectionTitle>
				<SectionMembers></SectionMembers>
				<div className="container">
					{bloggItems.slice(1).map(
						(item) => <BloggItem key={item.id} data={item} />
					)}
				</div>
			</span>
		);

	}
}
export default BloggItemList;