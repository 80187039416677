import React, { Component } from 'react';
import BloggItemList from './BloggItemList';
import Footer from './Footer';
import { connect } from 'react-redux';
import { fetchPosts } from '../actions/index';

class App extends Component {

	componentDidMount() {
		if (!this.props.posts) {
			this.props.fetch();
		}

	}

	render() {

		if (!this.props.posts) {
			return <div>Loading...</div>;
		}

		return (
			<div>
				<a className="sticky-container" href="mailto:oscar@raccoon.se"><span></span></a>
				<BloggItemList posts={this.props.posts}></BloggItemList>
				<Footer></Footer>
			</div>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return {
		fetch: () => dispatch(fetchPosts())
	}
}

function mapStateToProps(state) {

	return { posts: state.posts.all };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);