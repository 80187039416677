import React from 'react';

const Footer = (props) =>  {
    return (
        <div className="footer">
            <span className="footer__text"> &copy; Spelfarsan med vänner 2018</span>
        </div>
    );

}
export default Footer;